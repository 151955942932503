import React from 'react';
import { NavLink } from 'react-router-dom';
import departmentofconservation from '../Images/departmentofconservation.png';
import tindallfoundation from '../Images/The Tindall Foundation.jpg';
import foundationNorth from '../Images/foundationNorth.jpg';
import teUruRakau from '../Images/TeUruRakau.jpg';
import northlandRegionalCouncil from '../Images/northlandregionalcouncil.jpg';
import { FaFacebook } from 'react-icons/fa';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-contact">
        <h3>Contact</h3>
        <a href="mailto:cdavis@teroroa.iwi.nz">cdavis@teroroa.iwi.nz</a> <br />
        1 Waipoua River Road <br />
        Waipoua <br />
        09 439 6443 <br />
        <br />
        <div>
          <a
            href="https://l.facebook.com/l.php?u=https%3A%2F%2Ffacebook.us20.list-manage.com%2Fsubscribe%2Fpost%3Fu%3Da87388e632551b1282da07aa3%26amp%253Bid%3D6caa5dee4b%26fbclid%3DIwAR2psa0WA8CI963V854p8xh32ZrPl5RLq-iaOI3Tgrbx-YTG9MlBqosm8RI&h=AT2PcgKpOfqWYiUOr2n1ixfBx2qF6UgeViuah8zEe96luRC4pBdFnSs2J-95KtVKFV8d5NWRNu5FjmMb2WBhzEE-k276UG5WZkrpir8CTdJtEGgxp0lU1PwpPa1V0SqBiVteCT67u5A5kx4Ydmpj0tEo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up to our Newsletter!
          </a>
        </div>
        <br />
        <a
          style={{ fontSize: '2em' }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/tetoawhenua"
        >
          <FaFacebook />
        </a>
      </div>
      <div className="footer-sponsors">
        <p>
          Special thanks to our supporters{' '}
          <span role="img" aria-label="praying emoji">
            🙏🏽
          </span>
        </p>
        <div className="desktop-sponsors-grid">
          <a
            href="https://www.doc.govt.nz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="images"
              src={departmentofconservation}
              alt="departmentOfConservsation"
            />
          </a>
          <a
            href="https://tindall.org.nz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="images"
              src={tindallfoundation}
              alt="tindallFoundation"
            />
          </a>
          <a
            href="https://www.foundationnorth.org.nz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="images"
              src={foundationNorth}
              alt="founationNorth"
            />
          </a>
          <a
            href="https://www.teururakau.govt.nz/te-uru-rakau-forestry-new-zealand"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="images" src={teUruRakau} alt="te Uru Rakau" />
          </a>
          <a
            href="https://www.nrc.govt.nz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="images"
              src={northlandRegionalCouncil}
              alt="te Uru Rakau"
            />
          </a>
        </div>
      </div>

      <div className="footer-menu">
        <nav className="footer-nav">
          <ul>
            <h3>Menu</h3>
            <li>
              <NavLink activeClassName="selected" exact to={'/'}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to={'/about'}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to={'/projects'}>
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to={'/donate'}>
                Donate
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to={'/contact'}>
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
