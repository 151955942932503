import React from "react";
import homepage from "../Images/homepage.jpg";
import map from "../Images/map.jpg";
import { useMediaQuery } from "react-responsive";

export default function Home() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1025px)",
  });
  return (
    <div className="content">
      <div className="poem">
        <p>Ko au te whenua </p>
        <p> Ko te whenua ko au </p>
        <p>I am the land </p>
        <p>The land is me</p>
      </div>
      <div className="home-img-wrapper">
        <img className="home-img" src={homepage} alt="homepage" />
      </div>
      <div className="home-img-wrapper">
        <img className="map" src={map} alt="map" />
      </div>
      <div className="home-text">
        {!isDesktopOrLaptop && <h1 className="mobile-titles">Our Vision</h1>}
        <p>
          The vision of Te Toa Whenua is for the ecological restoration of 900
          hectares of land returned to Te Roroa as part of the Treaty of
          Waitangi Settlement process. The restoration of this whenua will
          create an ecological corridor and protect significant archaeological
          sites within Te Toa Whenua, while establishing sustainable land uses
          between parts of Waipoua Forest nearest the Waipoua River and the sea
          north of Maunganui Bluff.
        </p>
        <p>
          Restoration of this land and the Waipoua River has been an
          intergenerational goal for the people of Te Roroa and represents the
          second phase of Te Roroa Cultural Redress aspirations under the Treaty
          Settlement Process.
        </p>
        <p>
          Te Toa Whenua signifies a powerful ambition for our people and
          potential blueprint to enable kaitiakitanga over our land. The project
          aims to develop a clear methodology to manage and restore the whenua
          back into high value indigenous habitat and diverse, productive land
          for current and future generations of Te Roroa Iwi.
        </p>
      </div>
    </div>
  );
}
