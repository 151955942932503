import React from "react";
import donate from "../Images/donate.jpg";
import donateButton from "../Images/donate-button.png";

export default function Donate() {
  return (
    <div className="donate-content">
      <div className="donate-text">
        <p>
          Like many community organisations Te Toa Whenua runs on a shoe-string
          budget and on kind donations from the wider community.
        </p>
        <p>
          If you would like to support us in our mahi please click the link
          below.
        </p>
        <div className="donate-button">
          <form
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top"
          >
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input
              type="hidden"
              name="hosted_button_id"
              value="UC2RLNYLPR8YN"
            />
            <input
              type="image"
              src={donateButton}
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
          </form>
        </div>
      </div>
      <img
        className="donate-img"
        src={donate}
        alt="donate"
        width="100%"
        style={{ float: "left" }}
      />
    </div>
  );
}
