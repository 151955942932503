import React from "react";
import kaimahi from "../Images/kaimahi.jpg";

export default function About() {
  return (
    <div className="content">
      <div className="about-text">
        <p>
          Renowned not only as a warrior, 19th Century Te Roroa chief Tūoho is
          celebrated as a highly skilled gardener, known to have been associated
          with gardens throughout the Waipoua including Matatina, Papatia, Huaki
          and Rewarewa at Maunganui.{" "}
        </p>
        <p>
          The phrase te toa o te whenua is derived from this lullaby composed by
          Tūohu’s cousin Taoho as a testament to his mana as an expert in the
          use of land and the management of people.
        </p>
        <p>
          It is through the application of the phrase ‘te Toa whenua’ to the
          project that we seek to reinvigorate the mana tupuna and mana whenua
          of the living descendants as magnificent and innovative gardeners in
          this restoration project.{" "}
        </p>
      </div>
      <div className="about-img-wrapper">
        <img className="about-img" src={kaimahi} alt="kaimahi" />
        <h4 className="caption">Kaimahi</h4>
      </div>
    </div>
  );
}
