import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import About from "./Components/About";
import Home from "./Components/Home";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";
import Donate from "./Components/Donate";
import Footer from "./Components/Footer";
import tetoawhenua from "./Images/tetoawhenua.jpg";
import "./App.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1025px)",
  });

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <header>
          <div className="container">
            <div className="title-logo">
              <div className="logo">
                <NavLink activeClassName="selected" to={"/"}>
                  <img src={tetoawhenua} alt="teToaWhenua" />
                </NavLink>
              </div>
              <div className="poem-landscape">
                <p>Ko au te whenua </p>
                <p> Ko te whenua ko au </p>
                <p>I am the land </p>
                <p>The land is me</p>
              </div>

              <NavLink activeClassName="selected" to={"/"}>
                <h3>Te Toa Whenua</h3>
              </NavLink>
            </div>

            <nav className="nav">
              <ul>
                <li>
                  <NavLink activeClassName="selected" to={"/about"}>
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="selected" to={"/projects"}>
                    Projects
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="selected" to={"/donate"}>
                    Donate
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="selected" to={"/contact"}>
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
      {isDesktopOrLaptop && (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/projects" component={Projects} />
          <Route path="/donate" component={Donate} />
        </Switch>
      )}
      {!isDesktopOrLaptop && (
        <>
          <Home></Home>
          <h1 className="mobile-titles">About</h1>
          <About></About>
          <h1 className="mobile-titles">Projects</h1>
          <Projects></Projects>
          <Donate></Donate>
        </>
      )}
      <Footer></Footer>
    </Router>
  );
}

export default App;
