import React from "react";
import nativetrees from "../Images/TeToaWhenua-18.jpeg";
import plantingtrees from "../Images/TeToaWhenua-10.jpeg";
import landscape from "../Images/TeToaWhenua-46.jpeg";
import plants from "../Images/plants.jpg";
import tetoawhenua1 from "../Images/TeToaWhenua1.jpg";
import tetoawhenua3 from "../Images/TeToaWhenua3.jpg";
import tetoawhenua4 from "../Images/TeToaWhenua4.jpg";
import tetoawhenua5 from "../Images/TeToaWhenua5.jpg";

export default function Projects() {
  return (
    <div className="content-projects">
      <div className="project1">
        <h3>Reforestation and Native Tree Nursery</h3>
        <p>
          We have developed a small native tree nursery where we are propagating
          native trees to support our winter planting programmes.{" "}
        </p>
        <div>
          <img src={nativetrees} alt="native trees" />
          <img src={plantingtrees} alt="planting trees" />
          <img src={landscape} alt="landscape" />
          <img className="plants" src={plants} alt="plants" />
        </div>
      </div>
      <div className="project2">
        <h3>Pest and Weed Control</h3>
        <p>
          With much of the site being recently clear-felled pine forest, the
          exposed nature of the site and associated weed-burden presents huge
          challenges to re-establishing biodiversity and land productivity.
          However, given the rich heritage, relatively good soils, favourable
          micro-climate and dedication of supporters, the opportunities for
          landscape and community transformation are huge.{" "}
        </p>
        <div>
          <img src={tetoawhenua1} alt="tetoawhenua1" />
          <img src={tetoawhenua3} alt="tetoawhenua3" />
        </div>
        <p>
          Te Toa Whenua has a dedicated team who do extensive weed eradication
          work across the 900-hectare project area. We are also establishing a
          32km bait station network for possum control. Mustelid and cat control
          has been implemented in much of the Te Toa Whenua area and is being
          administered by Kaitiaki Kiwi.
        </p>
        {/* <div>
          <img src={landscape} alt="landscape" />
          <img className="plants" src={plants} alt="plants" />
        </div> */}
        <div>
          <img src={tetoawhenua4} alt="tetoawhenua4" />
          <img src={tetoawhenua5} alt="tetoawhenua5" />
        </div>
        <br />
        <h3 className="project-title-centered">Food Production</h3>
        <p className="project-text-centered">
          Te Toa Whenua has established a food forest and garden for local
          people to grow food.
        </p>
        <div className="video-player">
          <iframe
            src="https://player.vimeo.com/video/371797474?color=ffffff"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            title="video"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      {/* <div className="project3"></div> */}
    </div>
  );
}
